html, body {
  font-family: "monseratt" sans-serif !important;
}

.ghiframe {
  min-height: 300px;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

body {
  background: #141414 !important;
  color: #e6e6e6;
  margin: 0;
}

a, .NavLinks a, .NavLinksMini a {
  text-decoration: none;
  color: #e6e6e6;
}

a:hover, .NavLinks a:hover, .NavLinksMini a:hover {
  text-decoration: none;
  color: #e6e6e6;
}

.body {
  flex-grow: 1;
  padding: 20px;
}

.NavMain {
  height: 65px;
  min-height: 65px;
  background: #1d1d1d;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  font-size: 1.2em;
  box-shadow: 0 4px 20px rgba(17, 17, 17, 0.5);
  position: relative;
  z-index: 1;
}

.NavLinksMini {
  display: none;
  min-height: 65px;
  margin: 0 auto;
  justify-content: center;
  animation: menuin 0.2s ease-out forwards;
}

@keyframes menuin {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.NavLinks a,
.NavLinksMini a {
  padding: 25px 15px;
}
.NavLinks a:hover,
.NavLinksMini a:hover {
  background: #161617;
}

.NavLogo img {
  height: 40px;
}

.NavBrand {
  font-weight: bold;
  text-transform: uppercase;
}

.MenuButton {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  display: none;
}

.MenuButton div {
  background: white;
  height: 4px;
  margin-bottom: 4px;
  width: 20px;
  transition: all 0.2s ease-in-out;
}

.MenuButton.Open div:first-child {
  transform: translateY(200%) rotate(45deg);
}
.MenuButton.Open div:last-child {
  transform: translateY(-200%) rotate(-45deg);
}
.MenuButton.Open div:nth-child(2) {
  opacity: 0;
}

.content {
  margin: 0 auto;
  max-width: 978px;
}

@media only screen and (max-width: 650px) {
  .NavLinks {
    display: none;
  }
  .MenuButton {
    display: flex;
  }
  .NavLinksMini {
    display: flex;
  }
}


.project-card-image {
  height: 200px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  /* background-attachment: fixed; */
  /* background-image: url('https://cdn.instructables.com/FM9/XLGA/IATHJ03J/FM9XLGAIATHJ03J.LARGE.jpg?auto=webp&width=1024&fit=bounds'); */
}

.cardbuttons2 .btn {
  width: 45%;
}

.no-border {
  border: none;
}

.card-text {
  color: #b7b7b7;
}

.flipx {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}