
.popin-enter {
    transform: translateY(12px);
    opacity: 0;
  }
  .popin-enter.popin-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: transform 0.475s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 0.475s ease-in-out;
  }
  
  .popin-exit {
    transform: translateY(-12px);
    opacity: 0;
    transition: transform 0.275s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.275s cubic-bezier(0.215, 0.61, 0.355, 1);
  }




  /* also good is 10 10 5 5 at 0.5s */

  .invalid {
    animation: shake 0.45s linear;
  }
  
  .doShake {
    animation: shake 0.45s linear;
  }
  
  @keyframes shake {
    8%,
    41% {
      transform: translateX(-7px);
    }
    25%,
    58% {
      transform: translateX(7px);
    }
    75% {
      transform: translateX(-4px);
    }
    92% {
      transform: translateX(4px);
    }
    0%,
    100% {
      transform: translateX(0);
    }
  }
  
  .fadeinonly-animation {
    animation-name: anim-fadein;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    animation-duration: 0.5s;
  }
  .moveuponly-animation {
    animation-name: anim-frombottom;
    animation-timing-function: ecubic-bezier(0.175, 0.885, 0.32, 1.275);
    animation-fill-mode: forwards;
    animation-duration: 0.5s;
  }
  
  .fadein-animation {
    animation-name: anim-frombottom, anim-fadein;
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275), ease-in-out;
    animation-fill-mode: forwards;
    animation-duration: 0.5s;
  }
  
  @keyframes anim-frombottom {
    from {
      transform: translatey(12px);
    }
  }
  
  @keyframes anim-fadein {
    from {
      opacity: 0;
    }
  }
  
  .fadeout-animation {
    animation-name: anim-totop, anim-fadeout;
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275), cubic-bezier(0.34, 0.66, 0.56, 1.28);
    animation-fill-mode: forwards;
    animation-duration: 0.375s;
  }
  
  @keyframes anim-totop {
    to {
      transform: translatey(-12px);
    }
  }
  
  @keyframes anim-fadeout {
    to {
      opacity: 0;
    }
  }
  
  .animate-onscreen-bottom {
    animation: onscreen-bottom 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
  }

  @keyframes onscreen-bottom {
    from {
      transform: translateY(100%);
      opacity: 0.8;
    }
  }
  
  @keyframes from-tx-zero {
    from {
      transform: translateX(0px);
    }
  }
  